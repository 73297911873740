import React, { useState, useEffect } from "react";
import axios from "axios";

const Gateway = () => {
  // State variables for RazorPay settings
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState("");
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState("");
  const [AccountName, setAccountName] = useState("");

  // State variables for Decentro settings
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);

  // State variables for additional settings
  const [ClassicLiteAuto, setClassicLiteAuto] = useState(false);
  const [ClassicProAuto, setClassicProAuto] = useState(false);
  const [OneTokenAuto, setOneTokenAuto] = useState(false);
  const [ThreeTokenAuto, setThreeTokenAuto] = useState(false);
  const [TwoTokenAuto, setTwoTokenAuto] = useState(false);
  const [ClassicExtremeAuto, setClassicExtremeAuto] = useState(false);
  const [autowithdraw, setAutowithdraw] = useState(false);
  const [autowithdrawlimit, setAutowithdrawlimit] = useState("");
  const [maxwithdraw, setMaxwithdraw] = useState("");
  const [sandboxid, setSandboxid] = useState("");
  const [sandboxkey, setSandboxkey] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  useEffect(() => {
    axios.get(baseUrl + "gatewaysettings/data").then((res) => {
      console.log(res.data);
      const data = res.data;
      setSettingId(data._id || "");
      setRazorpayout(data.RazorPayout);
      setRazorDeposit(data.RazorDeposit);
      setRazorpayAuto(data.RazorpayAuto);
      setdecentropayout(data.decentroPayout);
      setdecentroDeposit(data.decentroDeposit);
      setdecentroAuto(data.decentroAuto);
      setRazorpayKey(data.RazorPayKey);
      setRazorpaysecretKey(data.RazorPaySecretKey);
      setAccountName(data.AccountName);
      setClassicLiteAuto(data.ClassicLiteAuto);
      setClassicProAuto(data.ClassicProAuto);
      setOneTokenAuto(data.OneTokenAuto);
      setThreeTokenAuto(data.ThreeTokenAuto);
      setTwoTokenAuto(data.TwoTokenAuto);
      setClassicExtremeAuto(data.ClassicExtremeAuto);
      setAutowithdraw(data.autowithdraw);
      setAutowithdrawlimit(data.autowithdrawlimit);
      setMaxwithdraw(data.maxwithdraw);
      setSandboxid(data.sandboxid);
      setSandboxkey(data.sandboxkey);
    });
  }, [baseUrl]);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const response = await axios.post(baseUrl + `gatewaysettings`, {
      settingId,
      RazorPayout,
      RazorDeposit,
      RazorpayAuto,
      RazorPayKey,
      RazorPaySecretKey,
      AccountName,
      decentroPayout,
      decentroDeposit,
      decentroAuto,
      ClassicLiteAuto,
      ClassicProAuto,
      OneTokenAuto,
      ThreeTokenAuto,
      TwoTokenAuto,
      ClassicExtremeAuto,
      autowithdraw,
      autowithdrawlimit,
      maxwithdraw,
      sandboxid,
      sandboxkey,
    });
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        enctype="multipart/form-data"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="">
              Razorpay Payout
            </label>
            <select
              className="form-control"
              name="RazorPayout"
              id="buttonrazpay"
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>

            <label htmlFor="buttonrazdep" className="">
              Razorpay Deposit
            </label>
            <select
              className="form-control"
              name="RazorDeposit"
              id="buttonrazdep"
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>

            <label htmlFor="buttonrazauto" className="">
              RazorPay Auto
            </label>
            <select
              className="form-control"
              name="RazorpayAuto"
              id="buttonrazauto"
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key</label>
            <input
              className="form-control"
              type="text"
              id="RazorpayKey"
              value={RazorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey</label>
            <input
              className="form-control"
              type="text"
              id="RazorpaysecretKey"
              value={RazorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="AccountName">Account Name</label>
            <input
              className="form-control"
              type="text"
              id="AccountName"
              value={AccountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
        </div>

        <h5 className="text-uppercase font-weight-bold my-3">Decentro</h5>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttondecpay" className="">
              Decentro payout
            </label>
            <select
              className="form-control"
              name="decentroPayout"
              id="buttondecpay"
              value={decentroPayout}
              onChange={(e) => setdecentropayout(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="">
              Decentro Deposit
            </label>
            <select
              className="form-control"
              name="decentroDeposit"
              id="buttondecdep"
              value={decentroDeposit}
              onChange={(e) => setdecentroDeposit(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>

            <label htmlFor="buttondecauto" className="">
              Decentro Auto
            </label>
            <select
              className="form-control"
              name="decentroAuto"
              id="buttondecauto"
              value={decentroAuto}
              onChange={(e) => setdecentroAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
        </div>

        <h5 className="text-uppercase font-weight-bold my-3">
          Additional Settings
        </h5>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="ClassicLiteAuto">Classic Lite Auto</label>
            <select
              className="form-control"
              name="ClassicLiteAuto"
              id="ClassicLiteAuto"
              value={ClassicLiteAuto}
              onChange={(e) => setClassicLiteAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="ClassicProAuto" className="">
              Classic Pro Auto
            </label>
            <select
              className="form-control"
              name="ClassicProAuto"
              id="ClassicProAuto"
              value={ClassicProAuto}
              onChange={(e) => setClassicProAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="OneTokenAuto" className="">
              One Token Auto
            </label>
            <select
              className="form-control"
              name="OneTokenAuto"
              id="OneTokenAuto"
              value={OneTokenAuto}
              onChange={(e) => setOneTokenAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="ThreeTokenAuto" className="">
              Three Token Auto
            </label>
            <select
              className="form-control"
              name="ThreeTokenAuto"
              id="ThreeTokenAuto"
              value={ThreeTokenAuto}
              onChange={(e) => setThreeTokenAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="TwoTokenAuto" className="">
              Two Token Auto
            </label>
            <select
              className="form-control"
              name="TwoTokenAuto"
              id="TwoTokenAuto"
              value={TwoTokenAuto}
              onChange={(e) => setTwoTokenAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="ClassicExtremeAuto" className="">
              Classic Extreme Auto
            </label>
            <select
              className="form-control"
              name="ClassicExtremeAuto"
              id="ClassicExtremeAuto"
              value={ClassicExtremeAuto}
              onChange={(e) => setClassicExtremeAuto(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="autowithdraw" className="">
              Auto Withdraw
            </label>
            <select
              className="form-control"
              name="autowithdraw"
              id="autowithdraw"
              value={autowithdraw}
              onChange={(e) => setAutowithdraw(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="autowithdrawlimit" className="">
              Auto Withdraw Limit
            </label>
            <input
              className="form-control"
              type="text"
              id="autowithdrawlimit"
              value={autowithdrawlimit}
              onChange={(e) => setAutowithdrawlimit(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="maxwithdraw" className="">
              Max Withdraw
            </label>
            <input
              className="form-control"
              type="text"
              id="maxwithdraw"
              value={maxwithdraw}
              onChange={(e) => setMaxwithdraw(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="sandboxid" className="">
              Sandbox ID
            </label>
            <input
              className="form-control"
              type="text"
              id="sandboxid"
              value={sandboxid}
              onChange={(e) => setSandboxid(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="sandboxkey" className="">
              Sandbox Key
            </label>
            <input
              className="form-control"
              type="text"
              id="sandboxkey"
              value={sandboxkey}
              onChange={(e) => setSandboxkey(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
